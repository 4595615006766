import { RecyclingCompanyService } from 'services/recycling-company-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { ServiceStatusService } from 'services/service-status-service';
import { ServiceStationService } from 'services/service-station-service';
import { SiteService } from 'services/site-service';
import { ServiceStationStorageService } from 'services/service-station-storage-service';
import { SpecialProductTypeService } from 'services/special-product-type-service';
import { NetTypeService } from 'services/net-type-service';
import { NetShapeService } from 'services/net-shape-service';
import * as moment from 'moment';
import { ServiceService } from 'services/service-service';
import { UserService } from 'services/user-service';

@autoinject
export class ServiceFilters {
  @bindable private serviceFilters: any;
  @bindable private getQuery;
  @bindable private context;
  @bindable private fields;
  @bindable private pageSize;
  @bindable private getServices;
  @bindable private toggleVisible;
  @bindable private getKey;
  @bindable private getKeyExternal;
  @bindable private isNetList;
  @bindable private customerId;
  @bindable private clearAll;

  private contextWithAllStatuses: Array<string> = [
    'service-invoicing-report',
    'service-antifouling-report',
    'service-repairs-report',
    'statistics',
    'customer-services',
    'net-services',
  ];

  private serviceFilterTypes = {
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    RADIO_SIMPLE: 'radioSimple',
    RANGE: 'range',
    RANGE_DATE: 'rangeDate',
  };

  private serviceFilterInputInFocus: object = {};
  private serviceFilterDatepickerInFocus: any;

  constructor(
    private serviceService: ServiceService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private recyclingCompanyService: RecyclingCompanyService,
    private serviceStatusService: ServiceStatusService,
    private eventAggregator: EventAggregator,
    private serviceStationService: ServiceStationService,
    private siteService: SiteService,
    private serviceStationStorageService: ServiceStationStorageService,
    private netTypeService: NetTypeService,
    private netShapeService: NetShapeService,
    private specialProductTypeService: SpecialProductTypeService,
    private i18n: I18N,
    private userService: UserService
  ) {}

  private attached() {
    /* this.setupServiceFilters(); */

    this.getQuery = this.serviceFilterGetFiltersQuery.bind(this);
    this.toggleVisible = this.serviceFilterToggleVisible.bind(this);
    this.clearAll = this.clearAllServiceFilters.bind(this);
  }

  // Helper-function for serviceFilters
  private serviceFilterValuesToArray(serviceFilter) {
    if (!serviceFilter?.values || Object.keys(serviceFilter.values).length == 0) {
      return null;
    }

    const values = Object.entries(serviceFilter?.values);
    const data = values?.map((it) => {
      const item = {
        key: it[0],
        value: it[1],
        object: null,
      };

      const objectRef = serviceFilter.options.find((it) => it?.Id == item.key);
      if (objectRef) {
        item.object = objectRef;
      }

      return item;
    });

    return data;
  }

  private i18nName(row) {
    if (!row) {
      return null;
    }

    const lang = this.i18n.getLocale();
    switch (lang) {
      case 'nb-NO':
        return row['Name'];
      case 'en':
        return row['NameEn'] ? row['NameEn'] : row['Name'];
      case 'es':
        return row['NameEs'] ? row['NameEs'] : row['NameEn'];
      default:
        return row['Name'];
    }
  }

  // Sets the selected-label that shows in the dropdown
  // Converts array to compact string etc.
  private async setServiceFilterValueLabel(serviceFilter) {
    // Checkbox
    if (serviceFilter.type === this.serviceFilterTypes.CHECKBOX) {
      const values: any = this.serviceFilterValuesToArray(serviceFilter)
        ?.filter((it) => it.value == true)
        ?.sort((a, b) => {
          if (a?.object?.SortIndex && b?.object?.SortIndex) {
            return a.object.SortIndex > b.object.SortIndex ? 1 : -1;
          } else {
            return 0;
          }
        });

      const fullString = values?.map((it: any) => this.i18nName(it.object)).join(', ');

      if (!values || values.length == 0) {
        serviceFilter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        serviceFilter._valueIsNone = true;
      } else if (values.length == 1) {
        serviceFilter._valueLabel = fullString.substring(0, 16);
        serviceFilter._valueIsNone = false;
      } else if (values.length > 1 && fullString.length > 16) {
        serviceFilter._valueLabel = `${fullString.substring(0, 16)} ...`;
        serviceFilter._valueIsNone = false;
      } else {
        serviceFilter._valueLabel = fullString.substring(0, 16);
        serviceFilter._valueIsNone = false;
      }
    }

    // Range
    if (serviceFilter.type === this.serviceFilterTypes.RANGE) {
      if (serviceFilter.values.from && serviceFilter.values.to) {
        serviceFilter._valueLabel = `${serviceFilter.values.from} - ${serviceFilter.values.to}`;
        serviceFilter._valueIsNone = false;
      } else if (serviceFilter.values.from) {
        serviceFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${
          serviceFilter.values.from
        }`;
        serviceFilter._valueIsNone = false;
      } else if (serviceFilter.values.to) {
        serviceFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${
          serviceFilter.values.to
        }`;
        serviceFilter._valueIsNone = false;
      } else {
        serviceFilter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        serviceFilter._valueIsNone = true;
      }
    }

    // Range Date
    if (serviceFilter.type === this.serviceFilterTypes.RANGE_DATE) {
      if (serviceFilter.values.from && serviceFilter.values.to) {
        serviceFilter._valueLabel = `${moment.utc(serviceFilter.values.from).format('DD.MM.YYYY')} - ${moment
          .utc(serviceFilter.values.to)
          .format('DD.MM.YYYY')}`;
        serviceFilter._valueIsNone = false;
      } else if (serviceFilter.values.from) {
        serviceFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${moment
          .utc(serviceFilter.values.from)
          .format('DD.MM.YYYY')}`;
        serviceFilter._valueIsNone = false;
      } else if (serviceFilter.values.to) {
        serviceFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${moment
          .utc(serviceFilter.values.to)
          .format('DD.MM.YYYY')}`;
        serviceFilter._valueIsNone = false;
      } else {
        serviceFilter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        serviceFilter._valueIsNone = true;
      }
    }

    // Radio simple
    if (serviceFilter.type === this.serviceFilterTypes.RADIO_SIMPLE) {
      const selected = serviceFilter.options.find(({ value }) => value === serviceFilter.values);
      serviceFilter._valueLabel = selected.label;
    }

    // Radio
    if (serviceFilter.type === this.serviceFilterTypes.RADIO) {
      setTimeout(() => {
        const selected = serviceFilter.options.find(({ Id }) => Id === serviceFilter.values);
        serviceFilter._valueLabel = this.i18nName(selected) ?? this.i18n.tr('servicefilters.noneSelected');
        serviceFilter._valueIsNone = this.i18nName(selected) ? false : true;
      });
    }
  }

  // Sets the _selectedOptions on applicable filters
  // This array is just a helper for the frontend-logic and repeaters
  private setServiceFilterSelectedOptions(serviceFilter) {
    if (serviceFilter.type === this.serviceFilterTypes.CHECKBOX) {
      const selected = serviceFilter.options.filter((option) => {
        return serviceFilter.values[option.Id];
      });

      serviceFilter._selectedOptions = selected;
    }
  }

  private setServiceFiltersDisabled() {
    for (const [key, serviceFilter] of Object.entries(this.serviceFilters) as any) {
      if (serviceFilter && typeof serviceFilter === 'object') {
        if (serviceFilter.disabled !== undefined) {
          if (typeof serviceFilter.disabled === 'function') {
            this.serviceFilters[key]._disabled = serviceFilter.disabled(this.serviceFilters);
          } else {
            this.serviceFilters[key]._disabled = serviceFilter.disabled;
          }

          this.eventAggregator.publish('serviceFilterOptionsRefresh', key);
        }
      }
    }
  }

  private async serviceFilterGetFiltersQuery(fields = null): Promise<object> {
    return new Promise(async (resolve, reject) => {
      if (!this.serviceFilters) {
        await this.setupServiceFilters();
      }

      const query: object = {};

      const valuesToArray = (serviceFilter) => {
        if (serviceFilter.type === this.serviceFilterTypes.CHECKBOX) {
          return (
            Object.entries(serviceFilter?.values)
              .filter(([key, value]) => !!value)
              .map(([key]) => key) ?? []
          );
        } else {
          return serviceFilter?.values;
        }
      };

      const filters: Array<[string, any]> = Object.entries(this.serviceFilters);
      for (let [filterKey, filter] of filters) {
        if (!filter || typeof filter !== 'object') {
          continue;
        }

        // Has custom query
        if (filter.query) {
          // Servicefilter uses custom query-key
          if (typeof filter.query === 'string') {
            const values = valuesToArray(filter);
            if (values?.length > 0) {
              query[filter.query] = valuesToArray(filter);
            }
          }

          // Servicefilter uses custom query-function
          else if (typeof filter.query === 'function') {
            // Multiple values i returned
            const values = filter.query(filter.values);
            if (Array.isArray(values)) {
              if (values.length > 0) {
                values.forEach(({ key, value }) => {
                  query[key] = value;
                });
              }
            }

            // Single value is returned
            else {
              if (values.value && values.value !== '') {
                query[values.key] = values.value;
              }
            }
          }
        }

        // Use default query-handling
        else {
          // Range
          if (filter.type === this.serviceFilterTypes.RANGE) {
            query[`${filter.name}From`] = filter.values.from;
            query[`${filter.name}To`] = filter.values.to;
          }

          // Range Date
          if (filter.type === this.serviceFilterTypes.RANGE_DATE) {
            query[`${filter.name}From`] = filter.values.from
              ? moment.utc(filter.values.from).format('YYYY-MM-DD')
              : null;
            query[`${filter.name}To`] = filter.values.to ? moment.utc(filter.values.to).format('YYYY-MM-DD') : null;
          }

          // Radio simple
          if (filter.type === this.serviceFilterTypes.RADIO_SIMPLE) {
            if (filter.values !== null) {
              query[filter.name] = filter.values;
            }
          }

          // Radio
          if (filter.type === this.serviceFilterTypes.RADIO) {
            query[filter.name] = filter.values;
          }
        }
      }

      // Misc
      query['_select'] = fields
        ? fields
            ?.filter((x) => x.selected && !x.function)
            .map((x) => x.field)
            .join(',')
        : this.fields
            ?.filter((x) => x.selected && !x.function)
            .map((x) => x.field)
            .join(',');
      query['_export'] = false;
      query['top'] = this.pageSize;

      return resolve(query);
    });
  }

  // Radio-buttons needs a middle-function because of some
  // binding-issues, and because the click-event has to
  // get a true in return
  private serviceFilterRadioChangeProxy(serviceFilter, option, event) {
    setTimeout(() => {
      this.serviceFilterChange(serviceFilter, option, event);
    });
    return true;
  }

  // Datepickers need a middle-function to better handle changes
  private serviceFilterDateChangeProxy(serviceFilter, option, event) {
    if (!event.firedBy) {
      return this.serviceFilterChange(serviceFilter, option, event);
    }

    const date = event.firedBy._d;
    if (
      date &&
      serviceFilter?.values[option] &&
      moment.default(date).format('YYYY-MM-DD') == moment.default(serviceFilter?.values[option]).format('YYYY-MM-DD')
    ) {
      return;
    }

    // hack because datepicker is slow
    setTimeout(() => {
      this.serviceFilterChange(serviceFilter, option, event);
    }, 500);
  }

  private async serviceFilterChange(serviceFilter, option, event) {
    // Filter has custom change-function
    if (serviceFilter.change) {
      serviceFilter.change(option, event, serviceFilter.values);
    }

    // Use default change-functions
    else {
      if (serviceFilter.type === this.serviceFilterTypes.CHECKBOX) {
        serviceFilter.values[option.Id] = event.target.checked;
        serviceFilter._clearable = Object.values(serviceFilter.values).includes(true);
      }

      if (serviceFilter.type === this.serviceFilterTypes.RANGE) {
        serviceFilter._clearable = serviceFilter.values.from || serviceFilter.values.to;
      }

      if (serviceFilter.type === this.serviceFilterTypes.RANGE_DATE) {
        serviceFilter.values[`${serviceFilter.name}${option}`] = event.detail;
        serviceFilter._clearable = serviceFilter.values.from || serviceFilter.values.to;
      }

      if (serviceFilter.type === this.serviceFilterTypes.RADIO_SIMPLE) {
        this.serviceFilters[serviceFilter.name].values = option.value;
        serviceFilter._clearable = serviceFilter.values !== null;
      }

      if (serviceFilter.type === this.serviceFilterTypes.RADIO) {
        this.serviceFilters[serviceFilter.name].values = option.Id;
        serviceFilter._clearable = serviceFilter.values !== null;
      }
    }

    this.setServiceFilterSelectedOptions(serviceFilter);
    this.setServiceFilterValueLabel(serviceFilter);
    this.setServiceFiltersDisabled();
    this.getServices();
    this.serviceFiltersSaveLocal();
  }

  private async serviceFilterClearSingle(serviceFilter) {
    await this.serviceFilterClear(serviceFilter, true);
    await this.getServices();
  }

  /**
   * Will clear a filter to a null-state
   * This will _not_ reset a filter to default value
   * @param serviceFilter
   * @param useDefaults Will ignore a filter's custom clear-function if set to `true`
   */
  private async serviceFilterClear(serviceFilter, useDefaults = false, batch = false) {
    if (!serviceFilter || typeof serviceFilter !== 'object') {
      return;
    }

    // Filter has custom clear-function
    if (serviceFilter.clear && !useDefaults) {
      serviceFilter.clear(serviceFilter.values);
    }

    // Use default clear-functions
    else {
      // Checkbox
      if (serviceFilter.type === this.serviceFilterTypes.CHECKBOX) {
        for (const [key, value] of Object.entries(serviceFilter.values)) {
          serviceFilter.values[key] = false;
        }
      }

      // Radio
      if (serviceFilter.type === this.serviceFilterTypes.RADIO) {
        serviceFilter.values = null;
      }

      // Radio simple
      if (serviceFilter.type === this.serviceFilterTypes.RADIO_SIMPLE) {
        serviceFilter.values = null;
      }

      // Range
      if (serviceFilter.type === this.serviceFilterTypes.RANGE) {
        serviceFilter.values.from = null;
        serviceFilter.values.to = null;
      }

      // Range Date
      if (serviceFilter.type === this.serviceFilterTypes.RANGE_DATE) {
        /* serviceFilter.values.from = null;
				serviceFilter.values.to = null; */
        serviceFilter.values = { from: null, to: null };
      }
    }

    serviceFilter._clearable = false;

    this.setServiceFilterSelectedOptions(serviceFilter);
    this.setServiceFilterValueLabel(serviceFilter);

    if (!batch) {
      this.setServiceFiltersDisabled();
      this.serviceFiltersSaveLocal();
    }

    if (!useDefaults && !batch) {
      this.getServices();
    }
  }

  private async clearAllServiceFilters() {
    for (const [key] of Object.entries(this.serviceFilters)) {
      await this.serviceFilterClear(this.serviceFilters[key], false, true);
    }
    this.setServiceFiltersDisabled();
    this.serviceFilters.searchText = null;
    this.serviceFilters.skip = 0;
    this.serviceFiltersSaveLocal();
    this.getServices();
  }

  private async serviceFilterReset(serviceFilter) {
    if (serviceFilter.defaultValues) {
      if (typeof serviceFilter.defaultValues === 'function') {
        serviceFilter.values = await serviceFilter.defaultValues();
      } else {
        serviceFilter.values = serviceFilter.defaultValues;
      }
    } else {
      this.serviceFilterClear(serviceFilter);
    }
  }

  private serviceFilterGetSelectedOptions(serviceFilter) {
    return serviceFilter.options.filter((option) => {
      return serviceFilter.values[option.Id];
    });
  }

  private setServiceFilterInputFocus(serviceFilter, state: boolean) {
    setTimeout(() => {
      this.serviceFilterInputInFocus[serviceFilter.name] = state;
    });
  }

  private serviceFilterSearchKeydown(serviceFilter, event) {
    if (event.key == 'Escape' || event.key == 'Esc' || event.keyCode == 27) {
      serviceFilter._search = null;
    }
    return true;
  }

  private serviceFilterToggleVisible(serviceFilter, event) {
    serviceFilter._visible = event.target.checked;
    this.serviceFilterClear(serviceFilter); // Will also trigger serviceFiltersSaveLocal
  }

  private serviceFiltersSaveLocal() {
    const data: any = {};

    for (const [key, value] of Object.entries(this.serviceFilters)) {
      const filter = this.serviceFilters[key];

      if (!filter || typeof filter === 'undefined') {
        continue;
      } else if (typeof filter === 'string' || typeof filter === 'number') {
        data[key] = filter;
      } else {
        data[key] = {
          values: filter.values,
          visible: filter._visible ?? false,
        };
      }
    }

    localStorage.setItem(this.getKey(), JSON.stringify(data));
  }

  public setupServiceFilters() {
    return new Promise<void>(async (resolve, reject) => {
      interface ServiceFilters {
        [key: string]: {
          /**
           * Name of the servicefilter
           */
          name: string;

          /**
           * The label that is used in frontend.
           */
          label: string;

          /**
           * Type of servicefilter. Should reference a constant from serviceFilterTypes.
           */
          type: string;

          /**
           * Options to use with the servicefilters. Can be an array, async function or normal function. Must return array.
           * If using RADIO_SIMPLE-type, a string can also be returned.
           */
          options?: Array<any> | string;

          /**
           * Used to reference the options-function. Used to refresh options.
           */
          optionsFunc?: any;

          /**
           * Defines how this servicefilter should be handled when creating query.
           * Accepts both `string` and `function`.
           *
           * If `string`: will use this field as key on query and will use servicefilter's `values` as value.
           *
           * If `function`: the function must return an object containing `key` and `value`.
           */
          query?:
            | string
            | {
                (values?: object):
                  | {
                      key: string;
                      value: string;
                    }
                  | Array<{
                      key: string;
                      value: string;
                    }>;
              };

          /**
           * This fields contains all set values for the servicefilter.
           */
          values?: string | object | Array<any> | boolean;

          /**
           * Sets default value for the servicefilter.
           */
          defaultValues?: string | object | Array<any> | any;

          /**
           * Creates a custom clear-function instead if default. Does not return anything.
           */
          clear?: { (values?: object) };

          /**
           * Used to make filters conditional based on other filters or values.
           */
          disabled?: { (serviceFilters?: object) } | boolean | void | any;

          /**
           * Label to show when filter is disabled.
           */
          disabledLabel?: string;

          /**
           * Used as an array-version of values. Mostly used in the frontend.
           */
          _selectedOptions?: any;

          /**
           * Used to set a filter to disabled.
           */
          _disabled?: any;

          /**
           * Helper for frontend to keep dropdown open when an related element - i.e. the datepicker - is open.
           * The datepicker-element is not a child of the servicefilter, and will use this variable to keep the state.
           */
          _childInFocus?: { from: boolean; to: boolean };

          /**
           * Used by localstorage and frontend to show/hide filters from list.
           */
          _visible?: boolean;

          /**
           * Used in view to show/hide the clear-button.
           */
          _clearable?: boolean;

          dropdownRef?: HTMLElement;
        };
      }

      /* 
				GET OPTIONS FOR FILTERS
			*/
      const netTypeOptions: any = async () => {
        const data: any = await this.netTypeService.getAllCached().catch((err) => this.errorService.handleError(err));
        return data.filter((it: any) => it.IsFilterable);
      };

      const netShapeOptions: any = async () => {
        const data: any = await this.netShapeService.getAllCached().catch((err) => this.errorService.handleError(err));
        return data.filter((it: any) => !it.IsDeleted);
      };

      const specialProductTypes: any = async () => {
        const data: any = await this.specialProductTypeService
          .getAllCached()
          .catch((err) => this.errorService.handleError(err));
        return data.filter((it: any) => !it.IsDeleted);
      };

      const serviceStatusOptions: any = async () => {
        const data: any = await this.serviceStatusService
          .getAllCached()
          .catch((err) => this.errorService.handleError(err));
        const dataNew = [
          ...data,
          { Name: 'Kun aktive', NameEn: 'Active only', Id: -1, SortIndex: -1, NameEs: null, IsDeleted: false },
        ];
        return dataNew.filter((x) => !x.IsDeleted).sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));
      };

      const receivedFromSiteOptions: any = async () => {
        const customerIds = this.serviceFilters?.customerIds?._selectedOptions;

        if (customerIds && customerIds.length === 1) {
          const data = await this.siteService
            .getAll(`?$filter=CustomerId eq ${customerIds[0].Id}&$orderby=Name`)
            .catch((err) => this.errorService.handleError(err));
          return data;
        } else {
          return [];
        }
      };

      const customerIdsOptions: any = async () => {
        const data: any = await this.customerService.getAll();
        return data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
      };

      const serviceStationStorageOptions: any = async () => {
        const serviceStationId = this.serviceFilters?.serviceStationId.values;
        const data = await this.serviceStationStorageService.getAllCached();
        let filteredData = serviceStationId ? data.filter((x) => x.ServiceStationId == serviceStationId) : data;

        // Separate parents and children
        const parents = filteredData.filter((it) => !it.ParentId && !it.IsDeleted);
        const children = filteredData.filter((it) => it.ParentId && !it.IsDeleted);

        // Sort parents by name
        const parentsSorted = parents.sort((a, b) => (a.Name > b.Name ? 1 : -1));

        // Sort children by name and reverse
        const childrenSorted = children.sort((a, b) => (a.Name > b.Name ? 1 : -1)).reverse();

        // Init final array from parents
        const finalStorages = JSON.parse(JSON.stringify(parentsSorted));

        // Loop over children and place them at right index
        for (const child of childrenSorted) {
          const parentIndex = finalStorages.findIndex((it) => it.Id === child.ParentId);
          finalStorages.splice(parentIndex + 1, 0, { ...child, _isChild: true });
        }

        return finalStorages;
      };

      const serviceStationOptions: any = async () => {
        const data = await this.serviceStationService.getAllCached();
        setTimeout(() => this.eventAggregator.publish('serviceFilterOptionsRefresh', 'storageId'), 250);
        return data.filter((x) => !x.IsDeleted).sort((a, b) => (a.Name > b.Name ? 1 : -1));
      };

      /*
				SERVICEFILTERS
			*/
      const serviceFilters: ServiceFilters = {
        /* Kunde */
        customerIds: {
          name: 'customerIds',
          label: this.i18n.tr('general.customer'),
          type: this.serviceFilterTypes.CHECKBOX,
          options: await customerIdsOptions(),
          query: 'customerIds',
          clear: () => {
            this.serviceFilterClear(this.serviceFilters.customerIds, true);
            this.serviceFilterClear(this.serviceFilters.receivedFromSiteId, true);

            if (this.context === 'customer-services' || this.context === 'net-services') {
              serviceFilters.customerIds.values = { [this.customerId]: true };
            }
          },
        },

        /* Maskestørrelse */
        meshsize: {
          name: 'meshsize',
          label: this.i18n.tr('net.meshsizes'),
          type: this.serviceFilterTypes.RANGE,
        },

        /* Omkrets merd */
        circumference: {
          name: 'circumference',
          label: this.i18n.tr('netdimension.CircumferenceCage'),
          type: this.serviceFilterTypes.RANGE,
        },

        /* Målt omkrets */
        circumferenceMeasured: {
          name: 'circumferenceMeasured',
          label: this.i18n.tr('service.circumferenceMeasuredShort'),
          type: this.serviceFilterTypes.RANGE,
        },

        /* Dybde til bunntau */
        depth: {
          name: 'depth',
          label: this.i18n.tr('net.DepthBottomRope'),
          type: this.serviceFilterTypes.RANGE,
        },

        /* Grunndesign */
        netShape: {
          name: 'netShape',
          label: this.i18n.tr('netdimension.designType'),
          type: this.serviceFilterTypes.CHECKBOX,
          options: await netShapeOptions(),
          query: 'netShapeIds',
        },

        /* På utleveringsliste */
        hasPlannedDeliveryDate: {
          name: 'hasPlannedDeliveryDate',
          label: this.i18n.tr('servicefilters.hasPlannedDeliveryDate'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('servicefilters.hasPlannedDeliveryDateOnly'),
          defaultValues: async () => {
            if (this.context === 'service-delivery-report') {
              return true;
            } else {
              return null;
            }
          },
          clear: () => {
            this.serviceFilterClear(this.serviceFilters.hasPlannedDeliveryDate, true);
            if (this.context === 'service-delivery-report') {
              this.serviceFilters.hasPlannedDeliveryDate.values = true;
            }
          },
        },

        /* Vis spesialprodukter */
        showSpecialProducts: {
          name: 'showSpecialProducts',
          label: this.i18n.tr('servicefilters.showSpecialProducts'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('servicefilters.showSpecialProductsOnly'),
        },

        /* Type spesialprodukter */
        specialProductTypes: {
          name: 'specialProductType',
          label: this.i18n.tr('servicefilters.specialProductTypes'),
          type: this.serviceFilterTypes.CHECKBOX,
          options: await specialProductTypes(),
          query: 'specialProductTypeIds',
        },

        /* Kasserte varer */
        showDiscardedNets: {
          name: 'showDiscardedNets',
          label: this.i18n.tr('servicefilters.discardedNets'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('servicefilters.discardedNetsOnly'),
        },

        /* På lager */
        showNetsInStorage: {
          name: 'showNetsInStorage',
          label: this.i18n.tr('servicefilters.netsInStorage'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('servicefilters.netsInStorageOnly'),
        },

        /* Status */
        status: {
          name: 'status',
          label: this.i18n.tr('general.status'),
          type: this.serviceFilterTypes.CHECKBOX,
          options: await serviceStatusOptions(),
          defaultValues: async () => {
            if (this.context === 'service-station-storage-report') {
              let statuses = '1,2,3,4,5,6,7,10,11'.split(',').map((x) => +x);
              let res = {};
              statuses.forEach((x) => {
                res[x] = true;
              });
              return res;
            }
            if (this.contextWithAllStatuses.indexOf(this.context) != -1) {
              return { '-1': false };
            }
            return { '-1': true };
          },
          query: (values: object) => {
            const valuesArray = Object.entries(values)
              .filter(([key, value]) => value)
              .map(([key, value]) => key);

            if (valuesArray.length > 0) {
              if (values[-1]) {
                return { key: 'serviceStatusIds', value: valuesArray.join(',') + ',12,1,2,11,6,3,4,5,7,10' };
              } else {
                return { key: 'serviceStatusIds', value: valuesArray.join(',') };
              }
            } else {
              if (this.contextWithAllStatuses.indexOf(this.context) != -1) {
                return { key: 'serviceStatusIds', value: null };
              }
              return { key: 'serviceStatusIds', value: '12,1,2,11,6,3,4,5,7,10' };
            }
          },
          clear: (values: object) => {
            let defaultStatuses = null;

            if (this.context === 'service-station-storage-report') {
              defaultStatuses = '1,2,3,4,5,6,7,10,11'.split(',');
            }

            for (const [key, value] of Object.entries(values)) {
              if (defaultStatuses) {
                values[key] = defaultStatuses.indexOf(key) !== -1;
              } else if (key === '-1' && this.contextWithAllStatuses.indexOf(this.context) == -1) {
                values[key] = true;
              } else {
                values[key] = false;
              }
            }
          },
        },

        /* Type */
        netType: {
          name: 'netType',
          label: this.i18n.tr('servicefilters.netType'),
          type: this.serviceFilterTypes.CHECKBOX,
          options: await netTypeOptions(),
          query: 'netTypeIds',
        },

        /* Lagerlokasjon */
        storageId: {
          name: 'storageId',
          label: this.i18n.tr('filters.storageLocation'),
          type: this.serviceFilterTypes.RADIO,
          options: await serviceStationStorageOptions(),
          optionsFunc: serviceStationStorageOptions,
          disabled: (serviceFilters: any) => {
            return false;
          },
        },

        /* Servicestasjon */
        serviceStationId: {
          name: 'serviceStationId',
          label: this.i18n.tr('filters.servicestation'),
          type: this.serviceFilterTypes.RADIO,
          options: await serviceStationOptions(),
          optionsFunc: serviceStationOptions,
          defaultValues: async () => {
            const user: any = await this.userService.getCurrentUser();
            if (user?.ServiceStationId) {
              if (
                this.context !== 'customer-services' &&
                this.context !== 'net-services' &&
                this.context !== 'statistics'
              ) {
                return user.ServiceStationId;
              }
            }
            return null;
          },
          clear: async () => {
            this.serviceFilterClear(this.serviceFilters.serviceStationId, true);
            const user: any = await this.userService.getCurrentUser();
            if (user?.ServiceStationId) {
              if (
                this.context !== 'customer-services' &&
                this.context !== 'net-services' &&
                this.context !== 'statistics'
              ) {
                this.serviceFilters.serviceStationId.values = user.ServiceStationId;
              }
            }
          },
        },

        /* Mottatt fra lokalitet */
        receivedFromSiteId: {
          name: 'receivedFromSiteId',
          label: this.i18n.tr('service.receivedFromSite'),
          type: this.serviceFilterTypes.RADIO,
          options: await receivedFromSiteOptions(),
          optionsFunc: receivedFromSiteOptions,
          disabled: (serviceFilters: any) => {
            return serviceFilters.customerIds._selectedOptions.length !== 1;
          },
          disabledLabel: this.i18n.tr('filters.selectCustomerToShowSites'),
        },

        /* Mottatt dato */
        receivedDate: {
          name: 'receivedDate',
          label: this.i18n.tr('filters.receiveddate'),
          type: this.serviceFilterTypes.RANGE_DATE,
          defaultValues: () => {
            return { from: null, to: null };
          },
        },

        /* Vasket dato */
        washedDate: {
          name: 'washedDate',
          label: this.i18n.tr('filters.washeddate'),
          type: this.serviceFilterTypes.RANGE_DATE,
        },

        /* Testet dato */
        testedDate: {
          name: 'testedDate',
          label: this.i18n.tr('filters.testeddate'),
          type: this.serviceFilterTypes.RANGE_DATE,
        },

        /* Reparert dato */
        repairedDate: {
          name: 'repairedDate',
          label: this.i18n.tr('filters.repaireddate'),
          type: this.serviceFilterTypes.RANGE_DATE,
          defaultValues: async () => {
            if (this.context === 'service-repairs-report') {
              return {
                from: moment.utc().subtract(1, 'month').format('YYYY-MM-DD'),
                to: moment.utc().format('YYYY-MM-DD'),
              };
            } else {
              return { from: null, to: null };
            }
          },
          clear: () => {
            this.serviceFilterClear(this.serviceFilters.repairedDate, true);
            if (this.context === 'service-repairs-report') {
              this.serviceFilters.repairedDate.values = {
                from: moment.utc().subtract(1, 'month').format('YYYY-MM-DD'),
                to: moment.utc().format('YYYY-MM-DD'),
              };
            }
          },
        },

        /* Impregnert dato */
        antifouledDate: {
          name: 'antifouledDate',
          label: this.i18n.tr('filters.antifouleddate'),
          type: this.serviceFilterTypes.RANGE_DATE,
          defaultValues: async () => {
            if (this.context === 'service-antifouling-report') {
              return {
                from: moment.utc().subtract(1, 'month').format('YYYY-MM-DD'),
                to: moment.utc().format('YYYY-MM-DD'),
              };
            } else {
              return { from: null, to: null };
            }
          },
          clear: () => {
            this.serviceFilterClear(this.serviceFilters.antifouledDate, true);
            if (this.context === 'service-antifouling-report') {
              this.serviceFilters.antifouledDate.values = {
                from: moment.utc().subtract(1, 'month').format('YYYY-MM-DD'),
                to: moment.utc().format('YYYY-MM-DD'),
              };
            }
          },
        },

        /* Utleveringsdato */
        plannedDeliveryDate: {
          name: 'plannedDeliveryDate',
          label: this.i18n.tr('filters.planneddeliverydate'),
          type: this.serviceFilterTypes.RANGE_DATE,
        },

        /* Levet dato */
        deliveredDate: {
          name: 'deliveredDate',
          label: this.i18n.tr('filters.delivereddate'),
          type: this.serviceFilterTypes.RANGE_DATE,
        },

        /* Kassert dato */
        declinedServiceApproval: {
          name: 'declinedServiceApproval',
          label: this.i18n.tr('filters.declinedapproval'),
          type: this.serviceFilterTypes.RANGE_DATE,
        },

        recyclingCompanyId: {
          name: 'recyclingCompanyId',
          label: this.i18n.tr('service.recyclingCompany'),
          type: this.serviceFilterTypes.RADIO,
          options: await this.recyclingCompanyService.getAllCached(),
        },

        deliveredRecyclingCompany: {
          name: 'deliveredRecyclingCompany',
          label: this.i18n.tr('service.deliveredRecycling'),
          type: this.serviceFilterTypes.RANGE_DATE,
        },

        /* Fakturering (alle oppgaver) */
        showInvoicedAny: {
          name: 'showInvoicedAny',
          label: this.i18n.tr('filters.invoicedAny'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: [
            { label: this.i18n.tr('general.showReadyForInvoicing'), value: false },
            { label: this.i18n.tr('general.showInvoiced'), value: true },
            { label: this.i18n.tr('general.showAll'), value: null },
          ],
          defaultValues: async () => {
            if (this.context === 'service-invoicing-report') {
              return false;
            } else {
              return null;
            }
          },
          clear: () => {
            this.serviceFilterClear(this.serviceFilters.showInvoicedAny, true);
            if (this.context === 'service-invoicing-report') {
              this.serviceFilters.showInvoicedAny.values = false;
            }
          },
        },

        /* Fakturert vask */
        showInvoicedWashing: {
          name: 'showInvoicedWashing',
          label: this.i18n.tr('filters.invoicedWashing'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: [
            { label: this.i18n.tr('general.showReadyForInvoicing'), value: false },
            { label: this.i18n.tr('general.showInvoiced'), value: true },
            { label: this.i18n.tr('general.showAll'), value: null },
          ],
        },

        /* Fakturert testing */
        showInvoicedTesting: {
          name: 'showInvoicedTesting',
          label: this.i18n.tr('filters.invoicedTesting'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: [
            { label: this.i18n.tr('general.showReadyForInvoicing'), value: false },
            { label: this.i18n.tr('general.showInvoiced'), value: true },
            { label: this.i18n.tr('general.showAll'), value: null },
          ],
        },

        /* Fakturert reparasjon */
        showInvoicedRepair: {
          name: 'showInvoicedRepair',
          label: this.i18n.tr('filters.invoicedRepair'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: [
            { label: this.i18n.tr('general.showReadyForInvoicing'), value: false },
            { label: this.i18n.tr('general.showInvoiced'), value: true },
            { label: this.i18n.tr('general.showAll'), value: null },
          ],
        },

        /* Fakturert impregnering */
        showInvoicedAntifouling: {
          name: 'showInvoicedAntifouling',
          label: this.i18n.tr('filters.invoicedAntifouling'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: [
            { label: this.i18n.tr('general.showReadyForInvoicing'), value: false },
            { label: this.i18n.tr('general.showInvoiced'), value: true },
            { label: this.i18n.tr('general.showAll'), value: null },
          ],
        },

        /* Fakturert spaghetti */
        showInvoicedSpaghetti: {
          name: 'showInvoicedSpaghetti',
          label: this.i18n.tr('filters.invoicedSpaghetti'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: [
            { label: this.i18n.tr('general.showReadyForInvoicing'), value: false },
            { label: this.i18n.tr('general.showInvoiced'), value: true },
            { label: this.i18n.tr('general.showAll'), value: null },
          ],
        },

        /* Fakturert levering */
        showInvoicedDelivery: {
          name: 'showInvoicedDelivery',
          label: this.i18n.tr('filters.invoicedDelivery'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: [
            { label: this.i18n.tr('general.showReadyForInvoicing'), value: false },
            { label: this.i18n.tr('general.showInvoiced'), value: true },
            { label: this.i18n.tr('general.showAll'), value: null },
          ],
        },

        /* Målt dato */
        measuredDate: {
          name: 'measuredDate',
          label: this.i18n.tr('filters.measuredDate'),
          type: this.serviceFilterTypes.RANGE_DATE,
        },

        /* Gyldighetsdato */
        approvedExtendedPeriod: {
          name: 'approvedExtendedPeriod',
          label: this.i18n.tr('filters.approvedExtendedPeriod'),
          type: this.serviceFilterTypes.RANGE_DATE,
        },

        showCollect: {
          name: 'showCollect',
          label: this.i18n.tr('general.isMorenotCollect'),
          type: this.serviceFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('general.onlyCollectNet'),
          _visible: false,
          defaultValues: () => {
            return null;
          },
          clear: () => this.serviceFilterClearSingle('showCollect'),
        },
      };

      // Function for refreshing options-list
      // Used with filter-disabling
      this.eventAggregator.subscribe('serviceFilterOptionsRefresh', async (serviceFilterKey) => {
        this.serviceFilters[serviceFilterKey].options = await serviceFilters[serviceFilterKey].optionsFunc();
        this.setServiceFilterValueLabel(this.serviceFilters[serviceFilterKey]);
      });

      const serviceFiltersListLocal = JSON.parse(localStorage.getItem(this.getKey()));

      const serviceFiltersExternal = JSON.parse(localStorage.getItem(this.getKeyExternal()));

      if (serviceFiltersExternal) {
        for (const key in serviceFiltersExternal) {
          const filter = serviceFiltersExternal[key];
          if (typeof filter === 'undefined') {
            continue;
          } else if (typeof filter === 'string') {
            (<any>serviceFilters[key]) = filter;
          }
          if (typeof filter === 'object') {
            serviceFilters[key]._visible = filter.visible;
            serviceFilters[key].defaultValues = filter.values;
          }
        }
      }

      // Functions and stuff to do when loading filters for the first time

      // Default filters visible
      if (!serviceFiltersListLocal) {
        // Utleveringsliste
        if (this.context === 'service-delivery-report') {
          serviceFilters.meshsize._visible = true;
          serviceFilters.hasPlannedDeliveryDate._visible = true;
          serviceFilters.showSpecialProducts._visible = true;
          serviceFilters.showNetsInStorage._visible = true;
          serviceFilters.netType._visible = true;
          serviceFilters.storageId._visible = true;
          serviceFilters.serviceStationId._visible = true;
        }

        // Service fakturering
        else if (this.context === 'service-invoicing-report') {
          serviceFilters.customerIds._visible = true;
          serviceFilters.showInvoicedAny._visible = true;
          serviceFilters.showInvoicedWashing._visible = true;
          serviceFilters.showInvoicedTesting._visible = true;
          serviceFilters.showInvoicedRepair._visible = true;
          serviceFilters.showInvoicedAntifouling._visible = true;
          serviceFilters.showInvoicedSpaghetti._visible = true;
          serviceFilters.showInvoicedDelivery._visible = true;
          serviceFilters.serviceStationId._visible = true;
        }

        // service/notposer på lager
        else if (this.context === 'service-station-storage-report') {
          serviceFilters.customerIds._visible = true;
          serviceFilters.meshsize._visible = true;
          serviceFilters.hasPlannedDeliveryDate._visible = true;
          serviceFilters.showSpecialProducts._visible = true;
          serviceFilters.showNetsInStorage._visible = true;
          serviceFilters.netType._visible = true;
          serviceFilters.storageId._visible = true;
          serviceFilters.serviceStationId._visible = true;
        }

        // Spesialprodukter
        else if (this.context === 'special-product-list') {
          serviceFilters.customerIds._visible = true;
          serviceFilters.hasPlannedDeliveryDate._visible = true;
          serviceFilters.netType._visible = true;
          serviceFilters.serviceStationId._visible = true;
        } else if (this.context === 'service-repairs-report') {
          serviceFilters.customerIds._visible = true;
          serviceFilters.meshsize._visible = true;
          serviceFilters.hasPlannedDeliveryDate._visible = true;
          serviceFilters.showNetsInStorage._visible = true;
          serviceFilters.netType._visible = true;
          serviceFilters.storageId._visible = true;
          serviceFilters.repairedDate._visible = true;
          serviceFilters.serviceStationId._visible = true;
        } else if (this.context === 'service-antifouling-report') {
          serviceFilters.customerIds._visible = true;
          serviceFilters.meshsize._visible = true;
          serviceFilters.hasPlannedDeliveryDate._visible = true;
          serviceFilters.showNetsInStorage._visible = true;
          serviceFilters.netType._visible = true;
          serviceFilters.storageId._visible = true;
          serviceFilters.antifouledDate._visible = true;
          serviceFilters.serviceStationId._visible = true;
        }
        // Default (serviceoversikt)
        else {
          serviceFilters.customerIds._visible = true;
          serviceFilters.meshsize._visible = true;
          serviceFilters.circumference._visible = true;
          serviceFilters.depth._visible = true;
          serviceFilters.netShape._visible = true;
          serviceFilters.hasPlannedDeliveryDate._visible = true;
          serviceFilters.showDiscardedNets._visible = true;
          serviceFilters.showSpecialProducts._visible = true;
          serviceFilters.specialProductTypes._visible = true;
          serviceFilters.showNetsInStorage._visible = true;
          serviceFilters.status._visible = true;
          serviceFilters.netType._visible = true;
          serviceFilters.storageId._visible = true;
          serviceFilters.serviceStationId._visible = true;
          serviceFilters.receivedFromSiteId._visible = true;
        }
      }

      for (const [key, filter] of Object.entries(serviceFilters)) {
        // Set default values and helpers for filters
        if (!filter.values) {
          if (serviceFilters[key].type === this.serviceFilterTypes.CHECKBOX) {
            serviceFilters[key].values = {};
            serviceFilters[key]._selectedOptions = [];
          }

          if (serviceFilters[key].type === this.serviceFilterTypes.RADIO) {
            serviceFilters[key].values = null;
          }

          if (serviceFilters[key].type === this.serviceFilterTypes.RANGE) {
            serviceFilters[key].values = { from: null, to: null };
          }

          if (serviceFilters[key].type === this.serviceFilterTypes.RANGE_DATE) {
            serviceFilters[key].values = { from: null, to: null };
            serviceFilters[key]._childInFocus = { from: false, to: false };
          }

          if (serviceFilters[key].type === this.serviceFilterTypes.RADIO_SIMPLE) {
            serviceFilters[key].values = null;

            // Helper for RADIO_SIMPLE-filter, so we don't have to write
            // options for show and hide all the times
            if (typeof serviceFilters[key].options === 'string') {
              serviceFilters[key].options = [
                { label: this.i18n.tr('general.show'), value: null },
                { label: this.i18n.tr('general.hide'), value: false },
                { label: serviceFilters[key].options, value: true },
              ];
            }
          }
        }

        // Set values to default values, if any
        if (serviceFilters[key].defaultValues) {
          if (typeof serviceFilters[key].defaultValues === 'function') {
            serviceFilters[key].values = await serviceFilters[key].defaultValues();
          } else {
            serviceFilters[key].values = serviceFilters[key].defaultValues;
          }
        }

        // Handling localstorage - sets values and visible-state
        if (serviceFiltersListLocal) {
          if (serviceFiltersListLocal[key]) {
            serviceFilters[key]._visible =
              serviceFiltersListLocal[key].visible === undefined ? true : serviceFiltersListLocal[key].visible;
            serviceFilters[key].values = serviceFiltersListLocal[key].values;
          }
        }

        // Always hide fields on net-services context
        if (this.context === 'net-services') {
          serviceFilters[key]._visible = false;
        }

        // Show/hide clear-button
        if (serviceFilters[key].type === this.serviceFilterTypes.CHECKBOX) {
          serviceFilters[key]._clearable = Object.values(serviceFilters[key].values).includes(true);

          if (serviceFilters[key].defaultValues && typeof serviceFilters[key] === 'object') {
            const isDefault = Object.entries(serviceFilters[key].values).every(
              ([valueKey, valueValue]) => !valueValue || serviceFilters[key].defaultValues[valueKey] == valueValue
            );
            if (isDefault) {
              serviceFilters[key]._clearable = false;
            }
          }
        }

        if (serviceFilters[key].type === this.serviceFilterTypes.RANGE) {
          serviceFilters[key]._clearable = serviceFilters[key].values['from'] || serviceFilters[key].values['to'];
        }

        if (serviceFilters[key].type === this.serviceFilterTypes.RANGE_DATE) {
          serviceFilters[key]._clearable = serviceFilters[key].values['from'] || serviceFilters[key].values['to'];
        }

        if (serviceFilters[key].type === this.serviceFilterTypes.RADIO_SIMPLE) {
          serviceFilters[key]._clearable = serviceFilters[key].values !== null;
        }

        if (serviceFilters[key].type === this.serviceFilterTypes.RADIO) {
          serviceFilters[key]._clearable = serviceFilters[key].values !== null;
        }

        // Set selected options (works for checkboxes)
        this.setServiceFilterSelectedOptions(serviceFilters[key]);

        // Sets value-label
        this.setServiceFilterValueLabel(serviceFilters[key]);
      }

      // Some final overrides for contexts
      if (this.context === 'customer-services') {
        serviceFilters.customerIds._visible = false;
      }

      if (serviceFiltersListLocal) {
        if (serviceFiltersListLocal.orderBy) {
          serviceFilters.orderBy = serviceFiltersListLocal.orderBy;
        }
        if (serviceFiltersListLocal.orderByDirection) {
          serviceFilters.orderByDirection = serviceFiltersListLocal.orderByDirection;
        }
        if (serviceFiltersListLocal.skip) {
          serviceFilters.skip = serviceFiltersListLocal.skip;
        }
        if (serviceFiltersListLocal.top) {
          serviceFilters.top = serviceFiltersListLocal.top;
        }
        if (serviceFiltersListLocal.searchText) {
          serviceFilters.searchText = serviceFiltersListLocal.searchText;
        }
      }

      this.serviceFilters = serviceFilters;

      return resolve();
    });
  }

  private serviceFilterMouseEnter(serviceFilter) {
    this.setServiceFilterInputFocus(serviceFilter, true);

    if (serviceFilter.type === this.serviceFilterTypes.RANGE_DATE && serviceFilter._dropdownRef) {
      const left = window.scrollX + serviceFilter._dropdownRef.getBoundingClientRect().left;
      const width = 477; // Width of datepicker-filter
      const isOverflowing = left + width > window.innerWidth ? true : false;
      if (isOverflowing) {
        serviceFilter._dropdownRef.classList.add('servicefilter-dropdown--datefilter-overflow-fix');
      } else {
        serviceFilter._dropdownRef.classList.remove('servicefilter-dropdown--datefilter-overflow-fix');
      }
    }
  }
}
